import axios from 'axios';
import { get } from 'lodash';

function setupDriverAutocomplete(field, value, orderId) {
  let el = field;
  $(el).on('select2:select', () => {
    $(`#assignDriverBtn-${orderId}`).prop('disabled', false);
  });

  if (typeof el === 'string') el += ':not(.select2-hidden-accessible)';
  if(gon.drivers !== ''){
    $(el).select2({
      placeholder: 'Select a Driver',
      data: gon.drivers.map(driver => ({
        id: driver.id,
        text: `${driver.last_name}, ${driver.first_name}`,
        selected: value === driver.id
      }))
    });
  }
}

$(document).ready(() => {
  $('.assignDriverField:not(.select2-hidden-accessible)').each(
    (_index, field) => {
      const el = $(field);
      const driverId = el.data('driver-id');
      const orderId = el.data('order-id');

      setupDriverAutocomplete(field, driverId, orderId);
    }
  );
});

export default function initDriverAutocomplete(id) {
  const el = $(`#assignDriverField-${id}`);
  const driverId = el.data('driver-id');
  const orderId = el.data('order-id');

  setupDriverAutocomplete(`#assignDriverField-${id}`, driverId, orderId);
}

function sendMessage(request, canceled = false) {
  var url = 'http://localhost:8001/StarWebPRNT/SendMessage';

  var papertype = 'normal';
  let trader = new StarWebPrintTrader({url:url, papertype:papertype});

  trader.onError = function (response) {
    var msg = 'There is no cash drawer signed to you\n\n';
    var answer = confirm(msg);
    if (answer) {
      sendMessage(request);
      alert(msg)
    }
    else {
      hideNowPrinting();
    }
  }

  trader.onReceive = function (response) {
    let msg = '- onReceive -\n\n';

    msg += 'TraderSuccess : [ ' + response.traderSuccess + ' ]\n';
    msg += 'TraderStatus : [ ' + response.traderStatus + ',\n';

    if (trader.isCoverOpen            ({traderStatus:response.traderStatus})) {msg += '\tCoverOpen,\n';}
    if (trader.isOffLine              ({traderStatus:response.traderStatus})) {msg += '\tOffLine,\n';}
    if (trader.isCompulsionSwitchClose({traderStatus:response.traderStatus})) {msg += '\tCompulsionSwitchClose,\n';}
    if (trader.isEtbCommandExecute    ({traderStatus:response.traderStatus})) {msg += '\tEtbCommandExecute,\n';}
    if (trader.isHighTemperatureStop  ({traderStatus:response.traderStatus})) {msg += '\tHighTemperatureStop,\n';}
    if (trader.isNonRecoverableError  ({traderStatus:response.traderStatus})) {msg += '\tNonRecoverableError,\n';}
    if (trader.isAutoCutterError      ({traderStatus:response.traderStatus})) {msg += '\tAutoCutterError,\n';}
    if (trader.isBlackMarkError       ({traderStatus:response.traderStatus})) {msg += '\tBlackMarkError,\n';}
    if (trader.isPaperEnd             ({traderStatus:response.traderStatus})) {msg += '\tPaperEnd,\n';}
    if (trader.isPaperNearEnd         ({traderStatus:response.traderStatus})) {msg += '\tPaperNearEnd,\n';}

    msg += '\tEtbCounter = ' + trader.extractionEtbCounter({traderStatus:response.traderStatus}).toString() + ' ]\n';
  }
  trader.sendMessage({request:request});
  if (canceled) {
    $('#cancelForm').submit()
  }
  $('#continue_picked_up').attr('disabled', false);
  return  true
}
const charactersSpace = (text1, text2, w = 1, count= 32) => {
  let text1_length = text1 ? text1.length : 0
  let text2_length = text2 ? text2.length : 0
  return count - (text1_length + text2_length) * w
}
function SendPrint(canceled = false, open = true) {
  var builder = new StarWebPrintBuilder();
  var request = '';

  request = builder.createInitializationElement();
  request += builder.createAlignmentElement({position:'center'});
  let options = {
    year: 'numeric', month: 'long', day: 'numeric'
  };

  $.ajax({
    type: "GET",
    url: "/order/" + gon.orderId,
    success: function (data) {
      let printParams = data.order
      let quantity = data.quantity
      let store_name = data.store_name

      if (printParams.id) {
        request += builder.createQrCodeElement({data:`${printParams.id}`});
        request += builder.createTextElement({data:'\n'});
      }
      request += builder.createTextElement({characterspace:0});
      request += builder.createLogoElement({number:1});
      request += builder.createTextElement({data:'Powered by Logickit\n'});

      request += builder.createTextElement({data:'\n'});
      if(printParams.id){
        request += builder.createTextElement({data:'Order'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Order", '#' + printParams.id +'\n'))});
        request += builder.createTextElement({data: '#' + printParams.id +'\n'});

      }

      if(printParams.created_at ){
        request += builder.createAlignmentElement({position:'right'});
        request += builder.createTextElement({data: printParams.created_at.toLocaleString('en-US', options) + '\n'});
      }

      request += builder.createTextElement({data:'\n'});
      request += builder.createTextElement({data:'\n'});

      if(printParams.app_user){
        request += builder.createTextElement({data:'Retailer'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Retailer", store_name +'\n'))});
        request += builder.createTextElement({data: store_name +'\n'});
      }
      if(printParams.location ){
        request += builder.createAlignmentElement({position:'right'});
        request += builder.createTextElement({data: printParams.location.phone.replace(/(\d{3})(\d{3})(\d{4})/,"($1)$2-$3") + '\n'});
      }
      request += builder.createTextElement({data:'\n'});
      request += builder.createTextElement({data:'\n'});

      if(printParams.customer_name){
        request += builder.createTextElement({data:'Customer'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Customer", '#' + printParams.app_user.id +'\n'))});
        request += builder.createTextElement({data: '#' + printParams.app_user.id +'\n'});
      }
      if(printParams.customer_name && printParams.app_user){
        request += builder.createAlignmentElement({position:'right'});
        request += builder.createTextElement({data:printParams.customer_name + '\n'});
        request += builder.createTextElement({data:printParams.app_user.cell_phone.replace(/(\d{4})(\d{3})(\d{4})/,"($1)$2-$3") +'\n'});
      }
      request += builder.createAlignmentElement({position:'left'});
      request += builder.createTextElement({data:'\n'});
      request += builder.createTextElement({data: '-'.repeat(32)});
      request += builder.createTextElement({data:'\n'});

      request += builder.createTextElement({data:'Item'});
      request += builder.createTextElement({data: ' '.repeat(charactersSpace("Item", "QTY", 1, 17))});
      request += builder.createTextElement({data: "QTY"});
      request += builder.createTextElement({data: ' '.repeat(charactersSpace("QTY", "Price", 1, 14))});
      request += builder.createTextElement({data: "Price\n"});

      request += builder.createTextElement({data: '-'.repeat(32)});
      request += builder.createTextElement({data:'\n'});

      if(printParams.order_items){
        printParams.order_items.forEach(item=>{
          request += builder.createTextElement({data:item.categories_name});
          request += builder.createTextElement({data: ' '.repeat(charactersSpace(item.categories_name, item.quantity.toString(), 1, 16))});
          request += builder.createTextElement({data: item.quantity});
          request += builder.createTextElement({data: ' '.repeat(charactersSpace(item.quantity.toString(), `$${item.cost}`, 1, 13))});
          request += builder.createTextElement({data: `$${item.cost}\n`});
          request += builder.createTextElement({data: item.product_name + '\n\n'});
        })

      }

      request += builder.createTextElement({data: '-'.repeat(32)});
      request += builder.createTextElement({data:'\n'});
      if(quantity){
        request += builder.createTextElement({data:'Total number of items'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Total number of items",  `$${quantity.toString()}`))});
        request += builder.createTextElement({data: quantity});
        request += builder.createTextElement({data:'\n'});
      }
      request += builder.createTextElement({data: '-'.repeat(32)});
      request += builder.createTextElement({data:'\n'});

      if(printParams.subtotal > 0){
        request += builder.createTextElement({data:'Subtotal'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Subtotal", `$${printParams.subtotal}`))});
        request += builder.createTextElement({data: `$${printParams.subtotal}\n`});
      }
      if(printParams.sales_tax > 0) {
        request += builder.createTextElement({data: 'Sales Tax'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Sales Tax", `$${printParams.sales_tax.toFixed(2)}`))});
        request += builder.createTextElement({data: `$${printParams.sales_tax.toFixed(2)}\n`});
      }
      if(printParams.excise_tax > 0){
        request += builder.createTextElement({data:'Excise Tax'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Excise Tax", `$${printParams.excise_tax.toFixed(2)}`))});
        request += builder.createTextElement({data: `$${printParams.excise_tax.toFixed(2)}\n`});
      }
      if(printParams.city_tax > 0){
        request += builder.createTextElement({data:'City Tax'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("City Tax", `$${printParams.city_tax.toFixed(2)}`))});
        request += builder.createTextElement({data: `$${printParams.city_tax.toFixed(2)}\n`});
      }
      if(printParams.discount_amount > 0){
        request += builder.createTextElement({data:'Discount'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Discount",  `-$${printParams.discount_amount.toFixed(2)}`))});
        request += builder.createTextElement({data:`-$${printParams.discount_amount.toFixed(2)}\n`});
      }

      request += builder.createTextElement({data:'\n'});
      request += builder.createTextElement({data:'\n'});

      if(printParams.total_cost){
        request += builder.createTextElement({emphasis:true});
        request += builder.createTextElement({width:2});
        request += builder.createTextElement({data:'Total'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Total",  `$${printParams.total_cost.toFixed(2)}`, 1, 13))});
        if(canceled) {
          request += builder.createTextElement({data: `-$${printParams.total_cost.toFixed(2)}\n`});
        }else{
          request += builder.createTextElement({data: `$${printParams.total_cost.toFixed(2)}\n`});
        }
        request += builder.createTextElement({width:1});
        request += builder.createTextElement({emphasis:false});
      }
      request += builder.createTextElement({data:'\n'});
      if(printParams.payment_type){
        request += builder.createTextElement({data:'Payment Type'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Payment Type",  printParams.payment_type))});
        request += builder.createTextElement({data: printParams.payment_type +'\n'});
      }
      request += builder.createTextElement({data: '-'.repeat(32)});
      request += builder.createTextElement({data:'\n'});
      if(printParams.location.license_number){
        request += builder.createAlignmentElement({position:'center'});
        request += builder.createTextElement({data: `License #${printParams.location.license_number}\n`, fontSize: 10});
      }
      if ((printParams.payment_type == 'Cash' || printParams.payment_type == 'Debit') && open) {
        request += builder.createPeripheralElement({channel: 1, on: 200, off: 200});
      }
      request += builder.createTextElement({characterspace:0});
      request += builder.createCutPaperElement({feed:true});
      sendMessage(request, canceled);
    }
  });
}
function hideNowPrinting(){
  var intimer = setTimeout(function (){
    clearTimeout(intimer);
  }, 300);
}
const updateDriver = async orderId => {
  const driverId = get(
    $(`#assignDriverField-${orderId}`).select2('data'),
    '[0].id'
  );
  if (!driverId) return;

  await axios.put(`/orders/${orderId}/driver`, {
    new_driver_id: driverId
  });
};
function isValidUrl(_string) {
  let url_string;
  try {
    url_string = new URL(_string);
  } catch (_) {
    return false;
  }
  return url_string.protocol === "http:" || url_string.protocol === "https:" ;
}

$(document).on('submit', '.assignDriverForm', ev => {
  ev.preventDefault();
  ev.stopImmediatePropagation();

  const orderId = $(ev.currentTarget).data('order-id');
  updateDriver(orderId);

  return false;
});

$(document).on('click', '.confirmOrder', e => {
  e.stopImmediatePropagation();

  const orderId = $(e.currentTarget).data('order-id');

  axios.put(
    `orders/${orderId}/confirm`,
    {},
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
});


$(document).on('click', '.cancelOrder', e => {
  e.preventDefault();
  gon.orderId = $('#orderId').val()
  var status = $('#orderStatus').val()
  var payment_type = $('#orderPayment').val()
  if (status == 'Picked Up' && payment_type == 'Cash') {
    SendPrint(true);
  }else{
    $('#cancelForm').submit()
  }
});

$(document).on('click', '#addShipped', e => {
  gon.orderId = $(e.currentTarget).data('order-id');
  $('#change_status_shipped').modal('show');
})

  $('#shippedButton').unbind().click(e => {
    var trackingUrl = $('#trackingUrl').val();
  var trackingId = $('#trackingId').val();
  if (trackingUrl !== null && trackingUrl !== "" && trackingId !== null && trackingId !== "") {
    if(isValidUrl(trackingUrl)) {
      $.post("/change_status_to_shipped", {
        order_id:  gon.orderId,
        tracking_url: trackingUrl,
        tracking_id: trackingId
    })
    .done(function (data) {
      $('#change_status_shipped').modal('hide');
      });
    }else {
      alert('Please add correct Tracking Url');
    }
  }else {
    alert('Please add Tracking Url and Tracking Id');
  }
});
$("#cancelButton").on("click", function() {
  $('#change_status_shipped').modal('hide');
});
$(document).on('click', '.changeStatus', e => {
  e.stopImmediatePropagation();
  const orderId = $(e.currentTarget).data('order-id');
  const status = $(e.currentTarget).data('status');
  const paymentType = $(e.currentTarget).data('payment-type');
  const totalCost = $(e.currentTarget).data('total-cost');
  const enableLedgerGreen = $(e.currentTarget).data('enable-ledgergreen');
  gon.orderId = orderId;
  gon.status = status;
  gon.paymentType = paymentType;
  gon.totalCost = totalCost;
  gon.enableLedgerGreen = enableLedgerGreen;
  gon.selectedPaymentType = false
  $('.payment-type').css('border', '1px solid #A0A0A0')
  $('.payment-type-label').css('color', 'rgb(130, 130, 130)');
  $('.total-cost').text('$')
  $('.change-total-cost').text('$')
  if(status == 'Ready for Pickup'  && paymentType == 'Cash'){

    if(!gon.enableLedgerGreen){
      $('.ledgergreen').css('display', 'none');
      $('.ledgergreen-label').css('display', 'none');
      $('.model-content-payment').css('width', '1112px');
    }else{
      $('.ledgergreen').css('display', 'block');
      $('.ledgergreen-label').css('display', 'block');
      $('.model-content-payment').css('width', '1320px');
    }

    $('#select_payment_type').modal('show');
    return false;
  }
  axios.put(
    `/orders/${orderId}/change_status`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(function (response) {
    changeOrderStatusData(response.data, orderId);
  }).catch(function (error) {
    alert(error.response.data.message);
    console.log(error);
  });
  if(status == 'Ready for Pickup'  && paymentType !== 'Cash'){
    var paymentData = {
      order_id: orderId,
      amount_owed: gon.totalCost,
      amount_paid: null,
      change_owed: null
    }
    paymentHistory(paymentData)
    SendPrint()
  }
});

function changeOrderStatusData(data, orderId){
  if(data && data.status == 'Ready for Pickup') {
    $("#orderStatus-" + orderId + "  button").html('Picked Up');
    $("#orderStatus-" + orderId + "  button").removeData('status');
    $("#orderStatus-" + orderId + "  button").attr('data-status','Ready for Pickup');
    $('#orderStatusId a').removeClass("btn-warning");
    $('#orderStatusId a').addClass("btn-info");
    $('#orderStatusId a').html("Ready for Pickup");
  }
  if(data && data.status == 'Picked Up') {
    $("#orderStatus-" + orderId + "  button").remove();
    $('#orderStatusId a').removeClass("btn-info");
    $('#orderStatusId a').addClass("btn-success");
    $('#orderStatusId a').html("Picked Up");
  }
}

$(document).on('click', '.payment-type', function (e) {
  $('.payment-type').css('border', '1px solid #A0A0A0');
  $('.payment-type-label').css('color', '#828282');
  $(this).css('border', '2px solid black');
  $(this).next('p').css('color', 'black');
  $('#amount_paid').val('');
  gon.selectedPaymentType = $(this).data('payment');
  $('#selected_payment_type').val($(this).data('payment'));
});

$('#continue_payment_type').unbind().click(function (e) {
  let payment_type = $('#selected_payment_type').val();
  $('#select_payment_type').modal('hide');
  let  paymentData = {
    order_id: gon.orderId,
    amount_owed: gon.totalCost,
    amount_paid: null,
    change_owed: null
  }
  if (gon.selectedPaymentType) {
    if (gon.selectedPaymentType == 'debit') {
      let fee = 5 - parseFloat(gon.totalCost)%5;
      let amount = parseFloat(gon.totalCost) + parseFloat(fee);
      $.ajax({
        method: "POST",
        url: '/user_pay_info',
        data: {order_id: gon.orderId,  amount: amount.toFixed(2).toString() },
        success: function (data) {
          if(data.status) {
            changeOrderStatus(gon.orderId, gon.selectedPaymentType);
            paymentHistory(paymentData)
            SendPrint()
          } else {
            if (data.message.length) {
              $('.model-content-waiting ').css('height', '375px');
              $('#cancel_debit_waiting').css('display', 'block');
              $('#debit_retry').css('display', 'block');
              $('.error-message-debit').text(data.message);
            }
          }
        },
        error: function (error, errorType) {
          if (errorType == 'timeout') {
            $('#debit_waiting').modal('hide');
            $("#debit-order-payment").modal('show');
            $('#close-debit-order').on('click', function (e) {
              $('#debit-order-payment').modal('hide');
              $('.total-cost').text('$' + gon.totalCost);
              $('#amount_paid').val(amount);
              $('#payment_amount').modal('show');
              $('#amount_paid').prop('disabled', true);
            });
            $('#dont-close-debit-order').on('click', function (e) {
              $("#debit-order-payment").modal('hide');
            })
          }
        },
        timeout: 30000
      });

    } else if (gon.selectedPaymentType == 'cash') {
      $('.total-cost').text('$' + parseFloat(gon.totalCost).toFixed(2))
      $('#payment_amount').modal('show');
    }else{
      paymentHistory(paymentData)
      changeOrderStatus(gon.orderId, gon.selectedPaymentType);
      SendPrint()
    }
  } else {
    alert('Please Select the payment type')
  }
});

$('#debit_retry').unbind().click(function (e) {
  $('#continue_payment_type').click()
})
function changeOrderStatus(orderId, paymentType){
  axios.put(
    `/orders/${orderId}/change_status`,
    {payment_type: paymentType},
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ).then(function (response) {
    changeOrderStatusData(response.data, orderId);
    $('#debit_waiting').modal('hide')
  }).catch(function (error) {
    $('#aeropay-modal').modal('show', {backdrop: 'static', keyboard: false});
    $('.aeropay-error-label').text(error.response.data.message);
  });
}
function paymentHistory(data){
  axios.put(
    `payment_history`,
    data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
}
$('#continue_picked_up').unbind().click(  function (e) {
  $('#change_owed').modal('hide');
  if (gon.selectedPaymentType == 'cash') {
    axios.put(
      `/orders/${gon.orderId}/change_status`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(function (response) {
      changeOrderStatusData(response.data, gon.orderId);
    }).catch(function (error) {
      console.log(error);
    });

    var data = {
      order_id: gon.orderId,
      amount_owed: gon.totalCost,
      amount_paid: gon.amount_paid,
      change_owed: gon.change_owed
    }
    paymentHistory(data)
  }

  if (gon.selectedPaymentType == 'debit') {
    var status;
    $.ajax({
      type: "GET",
      url: "/order/" + gon.orderId,
      success: function (data) {
       status = data.order.status;
      }
    })
    if (status !== "Picked Up") {
      changeOrderStatus(gon.orderId, gon.selectedPaymentType);
      SendPrint();
    }
  }
})

$('#continue_payment_amount').unbind().click(  function (e) {
  var amount_paid = $('#amount_paid').val()
  gon.amount_paid = amount_paid
  if(gon.selectedPaymentType == 'cash'){
    if(amount_paid && amount_paid >= gon.totalCost){
      var change_owed = amount_paid - gon.totalCost;
      gon.change_owed = change_owed;
      $('.change-total-cost').text('$' +  parseFloat(change_owed).toFixed(2));
      $('#payment_amount').modal('hide');
      $('#change_owed').modal('show');
      $('#continue_picked_up').attr('disabled', true);
      SendPrint()
    }else{
      alert('Please enter a paid amount')
    }
  }

  if (gon.selectedPaymentType == 'debit') {
    let fee = 5 - parseFloat(gon.totalCost)%5;
    $('.change-total-cost').text('$' +  parseFloat(fee).toFixed(2));
    $('#payment_amount').modal('hide');
    $('#change_owed').modal('show');
    $('#continue_picked_up').attr('disabled', false);
  }
})

$('#submit_cashbox').unbind().click(  function (e) {
  var amount = $('#cashbox_amount').val()
  var order_id = $('#cashbox_amount').val()
  if(amount === ''){
    alert('Please enter price')
    return false
  }
  $('#cashier_box').modal('hide');
  axios.put(
    `/set_starting_balance`,
    {amount, order_id},
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
})
$(document).on('click', '.cashierOrderReceiptId', function (e) {
  gon.orderId = $(this).attr('attr_order')
  let canceled = false
  let open = false
  SendPrint( canceled, open)
})
